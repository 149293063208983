.box-img {
  height: -webkit-fill-available;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  align-items: flex-end;
  display: flex;
  padding: 2rem;
  justify-content: center;
  h3 {
    font-size: 2.5rem;
    padding: 1.5rem;
    color: #fff;
    background-color: #a47864;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: 1s;
  }
}

.product-cat {
  height: 50vh;
}

/*------- 6. Product style  --------*/
.product-area {
  position: relative;
  .row {
    &.three-column {
      .col-xl-3 {
        flex: 1 0 33.3333%;
        max-width: 33.3333%;
        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;
          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;
          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    }
    &.five-column {
      .col-xl-3 {
        flex: 1 0 20%;
        max-width: 20%;
        transition: 0.5s;

        @media #{$lg-layout} {
          flex: 1 0 33.3333%;
          max-width: 33.3333%;
        }
        @media #{$md-layout} {
          flex: 1 0 50%;
          max-width: 50%;
        }
        @media #{$xs-layout} {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  &--style2 {
    .swiper-slider-navigation-wrapper {
      width: 100%;
      text-align: center;
    }
    .ht-swiper-button-nav {
      position: absolute;
      top: 15px;
      right: 0;
      display: inline-block;
      width: 35px;
      height: 35px;
      font-size: 28px;
      padding: 0;
      line-height: 1;
      text-align: center;
      border-radius: 5px;
      color: #c6c6c6;
      border: 1px solid #d2d2d2;
      background-color: #f9f9f9;
      transition: all 0.3s ease 0s;
      i {
        padding-top: 3px;
      }
      @media #{$xs-layout} {
        margin-top: 15px;
      }
      &:hover {
        color: #fff;
        border: 1px solid $theme-color;
        background-color: $theme-color;
      }
      &.swiper-button-prev {
        right: 40px;
        left: auto;
        @media #{$xs-layout} {
          margin-right: 10px;
        }
      }

      @media #{$xs-layout} {
        position: static;
        text-align: center;
      }
    }
  }
}

.product-wrap {
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid $secundary-color;
}

.product-wrap,
.product-list-image-wrap {
  position: relative;
  .product-img {
    position: relative;
    overflow: hidden;
    height: 100%;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        color: #fff;
        display: block;
        line-height: 1;
        padding: 3px 11px;
        border-radius: 3px;
        font-weight: 500;
        margin-bottom: 10px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: $theme-color;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 50%;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 20px);
      transition-duration: 0.7s;
    }
    .product-action {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      z-index: 9;
      transform: translateX(-50%);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > div {
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        transform: translateY(20px);
        background-color: $theme-color;
        transition: all 0.5s ease;
        // &:hover {
        //     background-color: #000;
        // }
      }
      .pro-wishlist {
        width: 48px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;
        width: calc(100% - 48px - 48px);
        transition-delay: 0.1s;
      }
      .pro-quickview {
        width: 48px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
        margin: 0;
        transition-delay: 0.2s;
      }
      .pro-same-action {
        a,
        button {
          color: #fff;
          font-size: 16px;
          height: 48px;
          line-height: 48px;
          display: block;
          width: 100%;
          text-align: center;
          text-transform: capitalize;
          background: none;
          border: none;
          &:hover,
          &.active {
            background-color: $theme-color;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    margin: 20px 0 0;
    h3 {
      font-size: 16px;
      margin: 0;
    }
    .product-rating {
      margin: 3px 0 3px;
      i {
        font-size: 17px;
        color: #5f5d5d;
        margin: 0 3px;
        &.yellow {
          color: #ffa900;
        }
      }
    }
    .product-price {
      span {
        font-size: 15px;
        color: #000;
        font-weight: 500;
        margin: 0 9px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          right: -13px;
          top: 10px;
          width: 7px;
          height: 2px;
          background-color: #000;
        }
        &:last-child:before {
          display: none;
        }
        &.old {
          color: #8e8e8e;
          text-decoration: line-through;
        }
      }
    }
  }
  &:hover .hover-img {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }
  &:hover .product-action > div {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.product-details-content {
  @media #{$lg-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$md-layout} {
    &.ml-70 {
      margin-left: 0;
    }
  }
  @media #{$xs-layout} {
    &.ml-70 {
      margin-left: 0;
      margin-top: 30px;
    }
  }
  &.quickview-content {
    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
  h2 {
    color: #010101;
    font-size: 24px;
    margin: 0 0 0px;
    line-height: 1;
  }
  .product-details-price {
    display: flex;
    align-items: center;
    margin: 15px 0 26px;
    span {
      font-size: 24px;
      color: #fe5252;
      &.old {
        color: #333;
        font-size: 18px;
        text-decoration: line-through;
        margin-left: 20px;
      }
    }
  }

  p {
    font-size: 15px;
    line-height: 28px;
    color: #333;
    margin: 0;
  }
  .pro-details-list {
    margin: 20px 0 34px;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 0 37px;
    ul {
      li {
        color: #333;
        margin: 0 0 5px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
.pro-details-quality {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 34px;
  .cart-plus-minus {
    border: 1px solid #e8e8e8;
    display: inline-block;
    height: 60px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 80px;
    .qtybutton {
      color: #8f8f8f;
      cursor: pointer;
      float: inherit;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      position: absolute;
      text-align: center;
      transition: all 0.3s ease 0s;
      width: 24px;
      background: none;
      border: none;
    }
    .dec.qtybutton {
      height: 60px;
      left: 0;
      top: 0;
    }
    .inc.qtybutton {
      height: 60px;
      right: 0;
      top: 0;
    }
    input.cart-plus-minus-box {
      background: transparent none repeat scroll 0 0;
      border: medium none;
      color: #8f8f8f;
      float: left;
      font-size: 14px;
      height: 60px;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 80px;
    }
  }
  .pro-details-cart {
    margin: 0 25px 0 10px;
    @media #{$xs-layout} {
      margin: 0 10px 0 10px;
    }
    @media #{$md-layout} {
      margin: 0 17px 0 10px;
    }
    a,
    button {
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      background: none;
      border: none;
      background-color: $theme-color;
      display: inline-block;
      line-height: 1;
      padding: 23px 38px 23px;
      @media #{$xs-layout} {
        padding: 23px 12px 23px;
      }
      @media #{$md-layout} {
        padding: 22px 22px 22px;
      }
      &:hover {
        border: none;
      }

      &:disabled {
        cursor: not-allowed;
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
}

.filter-active {
  position: relative;
  a,
  button {
    font-weight: 500;
    font-size: 18px;
    color: #010101;
    background: none;
    border: none;
    &:hover,
    &.active {
      color: $theme-color;
    }
    i {
      font-size: 14px;
    }
  }
}
.product-filter-wrapper {
  overflow: auto;
  overflow-y: auto;
  background-color: #fff;
  height: inherit !important;
  transition: height 0.4s ease-out;
  .Collapsible {
    padding-bottom: 1rem;
  }
  .Collapsible__contentOuter {
    padding-top: 1rem;
  }
  .Collapsible__trigger {
    font-family: $keepontruckin;
    font-weight: 400;
    margin-top: 0;
    letter-spacing: 0.5px;
    margin-left: 1rem;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 25px;
    padding-bottom: 7px;
    position: relative;
    text-transform: capitalize;
    &:hover {
      cursor: pointer;
      color: $theme-color;
    }
    &::before {
      background-color: #000;
      bottom: 0;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 20px;
    }
  }
  .product-filter {
    ul {
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        button {
          background: none !important;
          border: none !important;
          text-transform: capitalize !important;
          display: flex !important;
          flex-direction: row !important;
          align-items: center !important;
          div {
            width: 1rem !important;
            height: 1rem !important;
            border: 1px solid black !important;
            margin-bottom: 0.2rem !important;
            margin-right: 0.4rem !important;
          }
          &:hover,
          &.active {
            color: $theme-color !important;
            div {
              border: 1px solid $theme-color !important;
              background-color: $theme-color !important;
            }
          }
        }
      }
    }
    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 25px;
      padding-bottom: 7px;
      position: relative;
      text-transform: capitalize;
      &::before {
        background-color: #000;
        bottom: 0;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 20px;
      }
    }
    ul.sort-by {
      li {
        margin-bottom: 3px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
        }
      }
    }
    ul.color-filter {
      li {
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0px;
        }
        input {
          width: 20px;
          height: 20px;
          display: inline-block;
          float: left;
          margin: 3px 8px 0 0;
        }
        a {
          text-transform: capitalize;
        }
      }
    }
    .product-tags {
      ul {
        li {
          margin-bottom: 4px;
          a {
            text-transform: capitalize;
          }
        }
      }
    }

    &--tag {
      ul {
        li {
          display: inline-block;
          button {
            text-transform: lowercase;
            border: 1px solid #e6e6e6;
            margin-right: 10px;
            margin-bottom: 10px;
            &:hover {
              border-color: $theme-color;
            }
          }
        }
      }
    }
  }

  &__inner {
    @media #{$md-layout} {
      padding: 35px 25px 7px;
    }
    @media #{$xs-layout} {
      padding: 35px 25px 7px;
    }
  }
}

/* product hm3 */
/* product hm4 */

/* product home 8*/
.collection-product {
  .collection-img {
    overflow: hidden;
    a {
      img {
        width: 100%;
        transition: all 0.5s ease 0s;
        transform: scale(1);
      }
    }
  }
  .collection-content {
    margin: 23px 0 0;
    span {
      font-weight: 500;
    }
    h4 {
      margin: 4px 0 0;
      line-height: 1;
      a {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .collection-btn {
      color: #666;
      font-size: 13px;
      display: inline-block;
      border: 1px solid #010101;
      text-transform: uppercase;
      line-height: 1;
      padding: 8px 11px;
      background-color: transparent;
      font-weight: 400;
      border-radius: 50px;
      position: relative;
      overflow: hidden;
      margin-top: 15px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: auto;
        width: 0;
        height: 100%;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        z-index: -1;
      }

      &:hover {
        background-color: transparent;
        color: $white;
        border-color: $theme-color;
        &:after {
          background-color: $theme-color;
          width: 100%;
          left: 0;
          right: auto;
        }
      }
    }
  }
  &:hover a img {
    transform: scale(1.1);
  }
}

.product-large-image-wrapper {
  position: relative;
  .product-img-badges {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;
    span {
      font-size: 13px;
      color: #fff;
      display: block;
      line-height: 1;
      padding: 3px 11px;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 10px;
      &.pink {
        background-color: #fa6bff;
      }
      &.purple {
        background-color: $theme-color;
      }
    }
  }

  .react_lightgallery_item {
    position: absolute;
    top: 20px;
    right: 20px;
    button {
      background: none;
      border: none;
      font-size: 30px;
      padding: 0;
    }
  }

  &--slider {
    .ht-swiper-button-nav {
      background: none;
      border: none;
      font-size: 50px;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      color: #333;
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .single-image {
    img {
      width: 100%;
    }
  }
}
